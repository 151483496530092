import React, {useState, useEffect} from 'react';
import api_url from '../../api/myAPI' ;
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Sidebar from "../../scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode, tokens  } from "../../theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const Visits = () => {
  const userOfficeID = localStorage.getItem('userOfficeID');
  const [colorMode] = useMode();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [visitors, setVisitors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api_url.get(`/api/getOfficeVisitors/${userOfficeID}`);

        setVisitors(response.data.officeVisitors); 
        
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [userOfficeID]);

  const handleCall = (visitorId) => {
    // Logic for cancel
    console.log(`Call Visitor ${visitorId}`);
  };

  const handleCompleted = (visitorId) => {
    // Logic for approve
    console.log(`Completed Visit  ${visitorId}`);
  };

  const handleCancel = (visitorId) => {
    // Logic for approve
    console.log(`Cancelled Visitor Request with ID ${visitorId}`);
  };
  return (

    <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <Sidebar isSidebar={true} />
              <main className="content">
               
              <Box m="20px">
              <Header title="VISITOR'S REQUEST" subtitle="Managing the Visitors" />
              
      <Box
        m="40px 0 0 0"
        height="75vh"
        width="150vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        
        <div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>VISIT CODE</TableCell>
                <TableCell>FIRST NAME</TableCell>
                <TableCell>MIDDLE NAME</TableCell>
                <TableCell>LAST NAME</TableCell>
                <TableCell>CONTACT</TableCell>
                <TableCell>VISIT STATUS</TableCell>
                <TableCell>ACTION</TableCell>
                {/* Add more table headers as needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.visit_code}</TableCell>
                  <TableCell>{row.fname}</TableCell>
                  <TableCell>{row.mname}</TableCell>
                  <TableCell>{row.lname}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>{row.is_visit_status === 1 ? 'NEW'
                              : row.is_visit_status === 2 ? 'ON-GOING'
                              : row.is_visit_status === 3 ? 'COMPLETED'
                              : row.is_visit_status === 4 ? 'CANCELLED' 
                              : 'UNKNOWN STATUS'}</TableCell>
                      <TableCell> 
                      <Button variant="contained" onClick={() => handleCall(row.id)}> CALL</Button>&nbsp;
                      <Button variant="contained"  onClick={() => handleCompleted(row.id)}>COMPLETED  </Button>&nbsp;
                    
                      <IconButton color="secondary" aria-label="add an alarm" onClick={() => handleCancel(row.id)}>
  <DeleteIcon/> CANCEL
</IconButton> &nbsp;

                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        </div>
      </Box>
    </Box>
      
             


            </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
  );
};

export default Visits;
