import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './vms.css';

import { Button } from 'react-bootstrap';
import '../indexVMS.css';
function VMS() {
  return (
    <div className="cover-container">
        <div className="py-3 px-4">
            <Link to = "/visitor" >
                <Button className=" text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover">
                    <h5>TALK WITH US?</h5><h6>TAP HERE TO GET YOUR VISIT APPOINTMENT</h6>
                </Button>
            </Link>
        </div>
    </div>
  )
}

export default VMS;
