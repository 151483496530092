import { faEnvelope, faSave, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import api_url from '../../../api/myAPI';
import logo from '../../../assets/images/VMS.png';
import { Button } from 'react-bootstrap';
import '../../../indexVMS.css';

function Login() {
    const navigate = useNavigate();
    const emailRef = useRef();
    const [errormessage, setErrMsg] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        // Check if a login token exists (you can adjust this based on your token storage)
        const token = localStorage.getItem('token');
        if (token) {
          // Redirect to the home page or any other authenticated route
          navigate('/home');
        } else {
          // Redirect to the login page if no token is present
          navigate('/login');
        }
      }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        emailRef.current.focus();
        try {
            const response = await api_url.post('/api/login', {
                email,
                password,
            });
            if(response.data.error === true){
                Swal.fire({
                    title: "ERROR!",
                text: "Invalid Login Credentials.",
                timer: 5000,
                }); 
            }else if(response.data.success){
                console.log('Login successful');
                const token = response.data.token;
                const username = response.data.username;
                const userOfficeID = response.data.userOfficeID;
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                localStorage.setItem('userOfficeID', userOfficeID);
                Swal.fire({
                title: "Login Successfully!",
                timer: 4000,
                }).then(function (result) {
                    navigate("/home");
                });   
            }else if(response.data.denied){
                Swal.fire({
                title: "ERROR!",
                text: "Login Denied.",
                timer: 5000,
                });  
            }else if(response.data.notverified){
                Swal.fire({
                    title: "ERROR!",
                    text: "Email is not yet Verified.",
                timer: 5000,
                });  
            } 
        }catch (error) {
            console.error('Error posting data:', error);
            setErrMsg(error.error);
        }
    };  

  
    useEffect(() => {
        emailRef.current.focus();
    }, [])
  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex w-full flex-col md:flex-row">
          {/* Image */}
          <div className="md:bg-emerald-100 md:min-h-screen flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
              <img
                src={logo}
                alt="Logo Login"
               
                height="300px"
              />
            </div>
          </div>
          {/* Login Section */}
          <div className="flex flex-col md:flex-1 items-center justify-center">
            <div className=" loginWrapper flex flex-col w-full lg:px-36 md:px-8 px-8 md:py-8">
              <div className="relative h-px bg-gray-300">
                <div className="absolute left-0 top-0 flex justify-center w-full -mt-4">
                  <span className="  bg-white px-4  text-gray-500 uppercase font-medium text-xl">
                    VMS LOGIN 
                  </span>
                </div>
              </div>

              {/* Login Form */}
              <div className="md:mt-1">
                <form>
                  {/* Username */}
                  <div className="flex flex-col mb-3 mt-5">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>

                      <input
                        id="emailLogin"
                        type="text"
                        name="emailLogin"
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Email"
                        ref={emailRef}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faLock} />
                      </div>

                      <input
                        id="passwordLogin"
                        type="password"
                        name="passwordLogin"
                        onChange={(e) => setPassword(e.target.value)}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Password"
                       />
                    </div>
                    
                  </div>    
                    <div className="flex w-full">
                        <Button onClick={handleSubmit} className="flex items-center justify-center text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover md:rounded md:py-2 px-3 py-3 w-full transition duration-150 ease-in">
                            <FontAwesomeIcon icon={faSave} />
                             <span className="mr-2 flex-1">  Login</span>
                        </Button>
                    </div>
                    {errormessage && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                         {errormessage}
                        </span>
                    )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
