import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VMS from './visitor/VMS';
import Client from './visitor/Client';
import Login from './pages/authentication/login';
import Logout from './pages/authentication/Logout';
import Dashboard from "./scenes/dashboard";
import Visits from "./scenes/visits";
function App() {
  return (
      <>
        <div className="App">
            <Routes>
                  <Route path="/" element={<VMS />}/>
                  <Route path="/login" element={<Login />}/>
                  <Route path="/visitor" element={<Client />} />
                  <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
        <div className="app">
          <Routes>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/visitorRequest" element={<Visits />} />
      
            </Routes> 
        </div>
        </>

  );
}

export default App;
