import {useEffect} from "react";
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();
    const logout = () => {
      localStorage.clear();

      navigate("/login");
    }; 
     
  useEffect(() => {
   logout();
  }); 

    return null;
};

export default Logout;