import React, {useState, useEffect}from "react";
import logo from '../assets/images/VMS.png';
import api_url from '../api/myAPI' ;
import './visit.css';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';

const  Client = () => {
    const maxrequiredCheckboxes = 3;
    const minrequiredCheckboxes = 1;
    const [qrCodeData, setQrCodeData] = useState('');
    const [qrCodeText,  setQrCodeText] = useState('');
    const [dataArray, setDataArray] = useState([]);
    const [offices, setoffices] = useState([]);
    const [selectedoffices, setSelectedoffices] = useState([]);
    const [selectedofficesnames, setSelectedofficesnames] = useState([]);
    const [step, setStep] = useState(1);
    const [error, setError] = useState('');
    const [errorField, setErrorField] = useState(null);
    const [errorsubmit, setErrorSubmit] = useState(''); 
    const [formData, setFormData] = useState({
        fname: '',
        mname: '',
        lname: '',
        contact: '',
        office_id: '',      
    });

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value.toUpperCase(),
        });
    };

    const handleCheckboxChange = (e) => {
        const checkedValue = e.target.value;
        const checkedName = e.target.name;
        if(e.target.checked){
             if (selectedoffices.length >= maxrequiredCheckboxes) {
                setError('');
                e.target.checked =false;
                return;     
            } else {           
                setFormData({
                    ...formData,
                    office_id: e.target.value,
                });
                setSelectedoffices([...selectedoffices, checkedValue]);
                setSelectedofficesnames([...selectedofficesnames, checkedName.toUpperCase()]);
                setError('Please select at least ' + minrequiredCheckboxes + ' and maximum of '+ maxrequiredCheckboxes + ' checkboxes.');
            }
        }else{
            setSelectedoffices(selectedoffices.filter(item => item !== checkedValue));
            setSelectedofficesnames(selectedofficesnames.filter(item1 => item1 !== checkedName.toUpperCase()));
        }      
    };
  
    const validateStep = () => {
        switch (step) {
          case 1:
            if (formData.fname.trim() === '') {
              setErrorField('fname');
              return 'First Name';
            } else if (formData.mname.trim() === '') {
              setErrorField('mname');
              return 'MiddleName';
            }else if (formData.lname.trim() === '') {
                setErrorField('lname');
                return 'Last Name';
            }else if (formData.contact.trim() === '' || formData.contact.length > 11) {
                setErrorField('contact');
                return 'Contact';
              }
            break;
          default:
            
        }
        setErrorField(null); // If no empty field is found
        return null;
      };
    const nextStep = () => {
        if(step ===1){
             const emptyField = validateStep();
            if (!emptyField) {
               setStep(step + 1);
            } else {
   
            }
        }else if(step ===2){
            if (selectedoffices.length === 0) {
                setError('Please select at least ' + minrequiredCheckboxes + ' and maximum of '+ maxrequiredCheckboxes + ' checkboxes.');
            }else if(selectedoffices.length <= maxrequiredCheckboxes){
                setError(null);
                setStep(step + 1);   
            } else {
                setError('Please select at least ' + minrequiredCheckboxes + ' and maximum of '+ maxrequiredCheckboxes + ' checkboxes.');
            }          
            setDataArray(formData);          
        }else if(step === 3){
            setStep(step + 1);  
        } 
      };

    const prevStep = () => {
        setError('');
        setSelectedoffices('');
        setSelectedofficesnames('');
        console.log(selectedoffices);
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api_url.post('/api/visitRequest', {
                data: JSON.stringify({dataArray}),
            });
            if(response.data.error === true){
                setErrorSubmit(response.data.message);
            }else{
                setQrCodeText(response.data.qrcode);
                setQrCodeData(response.data.qrcode);
                setStep(step + 1); 
            }       
        } catch (error) {
            console.error('Error posting data:', error);
            setErrorSubmit(error.error);
        }
    };   
        const fetchData = async () => {
            try{
                return await api_url.get(`/api/office`)
                .then((response) => setoffices(response.data["offices"]));
            }catch (err){
                console.log("Something Went Wrong");
            }
        }
        useEffect(()=> {
            fetchData()
    
        }, []);

      const renderStep = () => {
        switch (step) {
          case 1:
            return (
            <div className="loginWrapper flex flex-col w-full lg:px-36 md:px-8 px-8 md:py-8">
                 <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-blue-500 rounded box-shadow">
                    <img className="mr-3" src="../assets/images/logo.png" alt="" width="72" height="72" />
                    <div className="lh-100">
                        <h4 className="mb-0 lh-100 font-medium self-center  sm:text-3xl text-white-500">VISITOR'S INFORMATION</h4>
                    </div>
                </div>
                <div className="md:mt-1">
                    <div className="">
                        <section>
                            <div className="bg-white w-auto h-auto pb-10 mx-5 rounded-lg sm:w-full md:w-4/5 md:mx-auto lg:w-3/5 lg:mx-auto">
                                <div className="mt-1 space-y-6 md:w-96">
                                    <div className="grid-container1">
                                        <div className="full-width-item"> 
                                            <div className="grid-item1">  
                                                <label>First Name:</label>
                                                <input
                                                autoFocus 
                                                type="text"
                                                maxLength={30}
                                                name="fname"
                                                value={formData.fname}
                                                onChange={handleChange}
                                                className={`${
                                                    errorField === 'fname' ? 'border-red-500 form-control' : ''
                                                } border p-2 rounded form-control`}
                                                />
                                                {errorField === 'fname' && (
                                                <p className="text-red-500">Please enter your first name.</p>
                                                )}
                                            </div>
                                           
                                        </div>
                                        <div className="full-width-item"> 
                                            <div className="grid-item1">
                                            <label>Middle Name:</label>
                                                <input
                                                type="text"
                                                name="mname"
                                                maxLength={30}
                                                value={formData.mname}
                                                onChange={handleChange}
                                                className={`${
                                                    errorField === 'mname' ? 'border-red-500 form-control' : ''
                                                } border p-2 rounded form-control`}
                                                />
                                                {errorField === 'mname' && (
                                                <p className="text-red-500">Please enter your middlename or put NA if not applicable.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="full-width-item"> 
                                            <div className="grid-item1">
                                                 <label>Last Name:</label>
                                                <input
                                                type="text"
                                                name="lname"
                                                maxLength={30}
                                                value={formData.lname}
                                                onChange={handleChange}
                                                className={`${
                                                    errorField === 'lname' ? 'border-red-500 form-control' : ''
                                                } border p-2 rounded form-control`}
                                                />
                                                {errorField === 'lname' && (
                                                <p className="text-red-500">Please enter your last name.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="full-width-item"> 
                                            <div className="grid-item1">
                                                <label>Contact:</label>
                                                <input
                                                type="number"
                                                name="contact"
                                                maxLength={11}
                                                value={formData.contact}
                                                onChange={handleChange}
                                                className={`${
                                                    errorField === 'contact' ? 'border-red-500 form-control' : ''
                                                } border p-2 rounded form-control`}
                                                />
                                                {errorField === 'contact' && (
                                                <p className="text-red-500">Please enter your contact details.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center mt-10">
                                        <button onClick={nextStep}  className="text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover"> NEXT </button>      
                                    </div>
                                </div>
                            </div>                  
                        </section>
                    </div>
                </div>
            </div>
            );
          case 2:
            return (
            <div className="loginWrapper flex flex-col w-full ">
                <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-blue-500 rounded box-shadow justify-center">
                    <img className="mr-3" src="../assets/images/logo.png" alt="" width="72" height="72" />
                    <div className="lh-100">
                        <h5 className="mb-0 lh-100 font-medium sm:text-3xl text-white-500">LIST OF OFFICES</h5>
                    </div>
                </div>

                <div className="relative mt-3 h-px bg-gray-300">
                    <div className="absolute left-0 top-0 flex justify-center w-full -mt-3">
                        <div className="bg-white px-3 text-sm text-gray-500 uppercase">
                             Select Offices to Visit (Maximum of 3 Offices per transaction per client )
                             
                        </div>
                    </div>
                  
                </div>
                <div className="md:mt-1">
                    <div className="">
                        <section>
                            <div className="bg-white w-auto h-auto rounded-lg sm:w-full md:w-4/5 md:mx-auto lg:w-2/5 lg:mx-auto">
                                    <div className="mt-3 space-y-6">
                                        <div className="grid-container">
                                        {
                                            offices.map((office, index) => (
                                                <div key={index} className="grid-item flex space-x-2">
                                                <input id={office.id} type="checkbox" className="w-4 h-4" value={office.id} name={office.office} onChange={handleCheckboxChange}/> 
                                                    <span className="text-sm">{office.office} </span> 
                                                </div>
                                            ))
                                        }
                                        </div>
                                        <div className="flex justify-center items-center mt-2 text-red-500">
                                        Note: Upon completion of office visit, you can submit new request to visit
                                       
                                        </div>
                                        {error && <p className = "flex justify-center items-center" style={{ color: 'red' }}>{error}</p>}
                                        <div className="flex justify-center items-center mt-2">
                                            <button onClick={prevStep} className="text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover mr-10"> PREVIOUS </button>
                                            <button onClick={nextStep} className="text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover mr-10"> NEXT </button>
                                        </div>  
                                    </div>
                                </div>
                        </section>
                    </div>
                </div>
            </div>
            );
            case 3:
        return (
          <div>
            <main role="main" className="container">
                <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-blue-500 rounded box-shadow">
                    <img className="mr-3" src="../assets/images/logo.png" alt="" width="48" height="48" />
                    <div className="lh-100">
                        <h4 className="mb-0  text-white-50  lh-100">Visitor's Information</h4>
                    </div>
                </div>
                <div className="my-3 p-3 bg-white rounded box-shadow border-2">
                    <div className="media text-muted">
                    
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark">First Name:</strong>
                    {formData.fname}
                    </p>
                    </div>
                    <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark">Middle Name:</strong>
                        {formData.mname}
                    </p>
                    </div>
                    <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark">Last Name:</strong>
                        {formData.lname}
                    </p>
                    </div>
                    <div className="media text-muted pt-3">
                    <p className="media-body mb-0 small lh-125 ">
                        <strong className="d-block text-gray-dark">Contact:</strong>
                        {formData.contact}
                    </p>
                    </div>   
                </div>
                <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-blue-500 rounded box-shadow">
                    <img className="mr-3" src="../assets/images/logo.png" alt="" width="48" height="48" />
                    <div className="lh-100">
                        <h4 className="mb-0  text-white-80  lh-100">Selected Offices to Visit</h4>
                    </div>
                </div>
                <div className="my-3 p-3 bg-white rounded box-shadow border-2">
                    <div className="media text-muted">
                    
                    <div className="media-body mb-0 small lh-125 border-gray">
                        <div className="d-flex justify-content-between align-items-center w-100">
                        <strong className="text-gray-dark">Office(s)</strong>
                        
                        </div>
                        <span className="d-block">
                         {selectedofficesnames.map((officesnames, index) => (
                            <React.Fragment key={index}>
                               {index+1}. {officesnames}
                              <br />
                            </React.Fragment>
                          ))}

                        </span>
                    </div>
                    </div>
                </div>
                </main>
                <div className="flex justify-center items-center mt-2">   
                    <button onClick={prevStep} className="text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover mr-10"> PREVIOUS </button>
                    <button onClick={handleSubmit} className="text-3xl py-3 px-4 border-b-4 border-red-700 font-extrabold rounded btn-start  btn-start:hover">Submit Request to Visit</button>
                </div>
                {errorsubmit && <p className = "flex justify-center items-center" style={{ color: 'red' }}>{errorsubmit}</p>}                
         
          </div>
        );
        case 4:
            return (
              <div>
                <main role="main" className="container">
                    <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-blue-500 rounded box-shadow">
                        <img className="mr-3" src="../assets/images/logo.png" alt="" width="48" height="48" />
                        <div className="lh-100">
                            <h4 className="mb-0  text-white-50  lh-100">Please proceed to PGC LOBBY area to check and monitor the approval of your request to visit</h4>
                        </div>
                    </div>
                    <div className="p-3 my-3 text-white-100 bg-white-500 rounded box-shadow border-2  justify-center">
                        <div className="flex justify-center items-center mt-2">  
                            <h2 className="text-50">Visitor's QRCODE</h2>
                        </div>
                        <div className="flex justify-center items-center mt-2">  
                            <h2 className="text-white-50">{qrCodeData && <QRCode value={qrCodeData} />}</h2>
                        </div>
                        <div className="flex justify-center items-center mt-2">  
                            <h2 className="text-50" id="qrcodeTextContainer">{ qrCodeText}</h2>
                        </div>
                    </div>
                    
  
                </main>
            </div>
            );
        default:
        return null;     
        }
      };
  return (
            <div className="flex min-h-screen">
                <div className="flex w-full flex-col md:flex-row">
                    <div className="md:bg-red-500 md:min-h-screen flex flex-wrap md:w-1/3" >
                        <div className="items-center text-center flex flex-col relative top-0 mx-auto">
                            <Link to = "/" >
                            <img
                                src={logo}
                                 alt="Logo Login"
                            />
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-1 items-center justify-center">
                        <div className="form-wizard">{renderStep()}</div>
                    </div>
                </div>
            </div>
  )
}

export default Client;
